<template>
  <!--begin::Basic info-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header border-0 bg-default">
      <!--begin::Card title-->
      <div class="card-title m-0 w-100 d-flex align-items-center justify-content-between">
        <div
          class="d-flex align-items-center justify-content-start cursor-pointer"
          @click="backMenu"
        >
          <i class="bi bi-arrow-left h3 me-4 back-menu text-white"></i>
          <h3 class="fw-bolder m-0 back-menu text-white" v-if="kelurahan">
            Grafik Suara Caleg Partai {{ party.name }} , Wilayah : {{ kelurahan.name }}
          </h3>
        </div>
        <select @input="getTps" name="" id="" class="form-select w-25">
          <option value="" selected>Pilih TPS</option>
          <template v-if="tps">
            <option v-for="(item, i) in tps" :key="i" :value="item.tps.id">
              {{ item.tps.no_tps }}
            </option>
          </template>
        </select>
      </div>
      <!--end::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Content-->
    <div id="kt_account_profile_details" class="collapse show">
      <!--begin::Card body-->
      <div class="card-body border-top p-9">
        <!--begin::Charts Widget 1-->
        <div class="card card-xl-stretch mb-xl-8">
          <!--begin::Body-->
          <div class="card-body">
            <div class="col-12 d-flex align-items-center">
              <img :src="path + party.logo" alt="" />
              <span class="fw-bolder ms-4">
                Jumlah Suara: {{ Intl.NumberFormat().format(party.suara) }}
              </span>
            </div>
            <!--begin::Chart-->
            <apexchart
              id="chart-candidate"
              :options="options"
              :series="series"
              height="400"
              @dataPointSelection="clickHandler"
            ></apexchart>
            <!--end::Chart-->
          </div>
          <!--end::Body-->
        </div>
        <!--end::Charts Widget 1-->
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import { getCSSVariableValue } from "@/assets/ts/_utils";
import ApexCharts from "apexcharts";
import CryptoJS from "crypto-js";

export default defineComponent({
  name: "Heatmaps-Chart-Detail",
  props: ["id", "wilayah"],
  setup(props) {
    setCurrentPageTitle("Heatmaps");

    const store = useStore();
    const router = useRouter();

    const path = window.location.origin + "/other/png/partai/";

    store.commit("SET_ACTIVE_MENU", "heatmaps");

    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = getCSSVariableValue("--bs-primary");
    const secondaryColor = getCSSVariableValue("--bs-gray-300");

    const dataId = CryptoJS.AES.decrypt(props.id, "PKS.id").toString(
      CryptoJS.enc.Utf8
    );
    const dataWilayah = CryptoJS.AES.decrypt(props.wilayah, "PKS.id").toString(
      CryptoJS.enc.Utf8
    );
    if (!dataId && !dataWilayah) {
      router.push({ name: "heat-maps.chart", params: { id: dataWilayah } });
    }

    const party = ref({
      name: "",
      logo: "",
      suara: "",
    });

    store.dispatch("getTPSResult", { territory: dataWilayah });
    store.dispatch("detailTerritory", dataWilayah );

    store.dispatch("getCandidateHeatmap", {
      territory: dataWilayah,
      party: dataId,
    });

    const data = computed(() => store.state.HeatmapModule.candidate);
    const tps = computed(() => store.state.ResultModule.tps);
    const kelurahan = computed(() => store.state.TerritoryModule.detail.territory);

    const options = {
      chart: {
        id: "chart-candidate",
        fontFamily: "inherit",
        height: "auto",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: ["30%"],
          endingShape: "rounded",
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return Intl.NumberFormat().format(val);
        },
        offsetX: 50,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: ["Caleg 1", "Caleg 2", "Caleg 3", "Caleg 4", "Caleg 5"],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      fill: {
        opacity: 1,
        color: ["#226c46"],
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return "Suara: " + Intl.NumberFormat().format(val);
          },
        },
      },
      colors: [baseColor, secondaryColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };

    const series = [
      {
        name: "Jumlah",
        data: [44, 55, 57, 61, 58],
      },
    ];

    watch(data, () => {
      if (data.value) {
        updateChart();
        party.value.name = data.value.party.name;
        party.value.logo = data.value.party.logo;
        party.value.suara = data.value.party.count;
      }
    });

    const updateChart = () => {
      const dummy = ref({
        name: [],
        data: [],
      });
      dummy.value.name = data.value.candidates.map((item) => {
        return item.name;
      });
      dummy.value.data = data.value.candidates.map((item) => {
        return item.count;
      });

      const categories = Object.values(dummy.value.name);

      const nilai = Object.values(dummy.value.data);

      ApexCharts.exec("chart-candidate", "updateOptions", {
        xaxis: {
          categories: categories,
        },
      });
      ApexCharts.exec(
        "chart-candidate",
        "updateSeries",
        [
          {
            data: nilai,
          },
        ],
        true
      );
    };

    const clickHandler = (event, chartContext, config) => {
      const index = config.dataPointIndex;
      // console.log(data.value[index]);
    };

    const getTps = (el) => {
      store.dispatch("getCandidateHeatmap", {
        territory: dataWilayah,
        party: dataId,
        tps: el.target.value,
      });
    };

    const backMenu = () => {
      router.push({ name: "heat-maps.chart", params: { id: props.wilayah } });
    };

    onUnmounted(() => {
      store.commit("SET_CANDIDATE_HEATMAP", []);
    });

    return {
      backMenu,
      options,
      series,
      party,
      path,
      tps,
      kelurahan,
      getTps,
      clickHandler,
    };
  },
});
</script>
